<!--
  PACKAGE_NAME : src\pages\ewm\demo\default-dx-component-test.vue
  FILE_NAME : default-dx-component-test
  AUTHOR : bykim
  DATE : 2024-01-29
  DESCRIPTION : DX 컴포넌트 테스트 페이지
-->
<template>
  <div class="container">
    <section>
      <div class="section-header">
        <h4>첨부파일 컴포넌트/API 테스트</h4>
      </div>
      <esp-dx-file-uploader v-model="fileList" :multiple="true" />
      <dx-button text="파일 업로드" :width="100" :height="30" class="white filled txt_S medium" @click="uploadFile" />
      <div :style="{ display: files.length > 0 ? 'block' : 'none' }" class="content-box">
        <div>
          <h4>업로드 파일 정보</h4>
          <div v-for="(file, index) in files" :key="index" class="selected-item">
            <span>Name: {{ file.name }}<br /></span>
            <span>Size {{ file.size }}<br /></span>
            <span>Type {{ file.type }}<br /></span>
            <span>Last Modified Date: {{ file.lastModifiedDate }}</span>
          </div>
        </div>
      </div>
      <div class="mb-10" />
      <DxTextBox
        v-model="groupId"
        :placeholder="'파일 그룹 아이디'"
        :width="250"
        :max-length="50"
        :input-attr="{ 'aria-label': 'Title' }"
        value-change-event="keyup"
      />
      <DxTextBox
        v-model="fileName"
        :placeholder="'파일명'"
        :width="250"
        :max-length="50"
        :input-attr="{ 'aria-label': 'Title' }"
        value-change-event="keyup"
      />
      <DxButton text="삭제" :width="50" :height="30" class="white filled txt_S medium" @click="deleteFile" />
      <DxButton text="다운로드" :width="100" :height="30" class="white filled txt_S medium" @click="downloadFile" />
      <DxButton text="파일 그룹 리스트 조회" :width="140" :height="30" class="white filled txt_S medium" @click="getListAttachFile" />
      <DxButton text="파일 URL 출력" :width="140" :height="30" class="white filled txt_S medium" @click="getAttachFileURL" />

      <div>
        {{ fileList }}
      </div>
    </section>
    <div class="mb-10" />
    <section>
      <div class="section-header">
        <h4>notify 테스트</h4>
        <DxTextBox
          v-model="notifyOptions.message"
          :placeholder="'메세지 입력'"
          :width="250"
          :max-length="50"
          :input-attr="{ 'aria-label': 'Title' }"
          value-change-event="keyup"
        />
        <DxTextBox
          v-model="notifyOptions.type"
          :placeholder="'success, error, warning, info 중 입력'"
          :width="250"
          :max-length="50"
          :hint="'success, error, warning, info 중 입력'"
          :input-attr="{ 'aria-label': 'Title' }"
          value-change-event="keyup"
        />
        <DxTextBox
          v-model="notifyOptions.displayTime"
          :placeholder="'displayTime(milliseconds)'"
          :width="250"
          :max-length="50"
          :type="'number'"
          :hint="'milliseconds'"
          :input-attr="{ 'aria-label': 'Title' }"
          value-change-event="keyup"
        />
        <DxButton text="공지 띄우기" :width="140" :height="30" class="white filled txt_S medium" @click="dxNotify" />
      </div>
    </section>
  </div>
</template>

<script>
  import { DxButton } from 'devextreme-vue/button';
  import { DxFileUploader } from 'devextreme-vue/file-uploader';
  import { DxTextBox } from 'devextreme-vue/text-box';
  import notify from 'devextreme/ui/notify';
  import { espApi } from '@/api';
  import EspDxFileUploader from '@/components/devextreme/esp-dx-file-uploader.vue';
  export default {
    components: { EspDxFileUploader, DxTextBox, DxButton, DxFileUploader, notify },
    props: {},
    data() {
      return {
        apiFileUpload: {},
        groupId: '',
        fileName: '',
        files: [],
        fileList: [],
        notifyOptions: {
          message: '알림 메시지',
          type: 'success',
          displayTime: '5000',
        },
      };
    },
    watch: {
      fileList: {
        handler: function (val) {
          this.files.push(...val);
        },
        deep: true,
      },
    },
    computed: {},
    methods: {
      deleteFile() {
        this.$_deleteAttachFile(this.groupId, this.fileName);
      },
      downloadFile() {
        this.$_downloadAttachFile(this.groupId, this.fileName);
      },
      async getListAttachFile() {
        this.fileList = await this.$_getListAttachFile(this.groupId);
      },
      getAttachFileURL() {
        this.$_Msg(`파일 URL: ${this.$_getAttachFileURL(this.groupId, this.fileName)}`);
      },
      dxNotify() {
        notify(this.notifyOptions.message, this.notifyOptions.type, this.notifyOptions.displayTime);
      },
      async uploadFile() {
        await espApi.fileUpload(this.files);
        this.$_Msg('파일 업로드 완료');
      },
    },
    created() {},
    mounted() {},
  };
</script>

<style lang="scss" scoped>
  .section-header {
    margin-bottom: 10px;
  }
  .section-header h4 {
    margin-bottom: 10px;
    font-weight: 500;
    font-size: 18px;
  }
  .content-box h4 {
    margin-bottom: 10px;
    font-weight: 500;
    font-size: 12px;
  }
  .content-box {
    margin-top: 5px;
    margin-left: 5px;
    margin-bottom: 10px;
    display: none;
  }
</style>